import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import { SingleArticle } from "../../components/SingleArticle/SingleArticle";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import AdSense from "react-adsense";

import "./ArticlesPage.scss";
import { NavBar } from "../../components/NavBar/NavBar";
import { menuItemsArticles, categoriesList } from "../../assets/MenuItems";
import { Link } from "react-router-dom";

export const ArticlesPage = ({ articles }) => {
  const [search, setSearch] = useState("");
  const [articlesList, setArticlesList] = useState(articles);
  const [activeCategory, setActiveCategory] = useState("");

  const navigate = useNavigate();

  const handleArticleId = (id) => {
    navigate(`/articles/${id}`);
  };

  const getCategoriesNumber = (name) => {
    return articles
      .filter((post) => post.status === "Публиковать")
      .filter((article) => article.chapter.toLowerCase() === name.toLowerCase())
      .length;
  };

  const categoriesList = [
    {
      name: "Новини",
      number: getCategoriesNumber("новини"),
    },
    {
      name: "Підбірки",
      number: getCategoriesNumber("Підбірки"),
    },
    {
      name: "Інтерв'ю",
      number: getCategoriesNumber("Інтерв'ю"),
    },
    {
      name: "Навколокнижкове",
      number: getCategoriesNumber("Навколокнижкове"),
    },
  ];

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setActiveCategory("");
  };

  const handleActiveCategory = (name) => {
    setSearch("");
    activeCategory === name ? setActiveCategory("") : setActiveCategory(name);
  };

  useEffect(() => {
    setArticlesList(
      articles.filter((item) =>
        item.title.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search]);

  useEffect(() => {
    setArticlesList(
      articles.filter((item) =>
        item.chapter.toLowerCase().includes(activeCategory.toLowerCase())
      )
    );
  }, [activeCategory]);

  useEffect(() => {
    setArticlesList(articles);
  }, [articles]);

  return (
    <>
      <NavBar menuItems={menuItemsArticles} />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Статті" />
        <title>Статті</title>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="article__page">
        {articlesList.filter((post) => post.status === "Публиковать").length ? (
          <div className="articles">
            {articlesList
              .filter((post) => post.status === "Публиковать")
              .sort((a, b) => b.articleId - a.articleId)
              .map((item) => (
                <div className="item" key={item.id}>
                  <SingleArticle article={item} />
                </div>
              ))}
          </div>
        ) : (
          <div className="articles"></div>
        )}
        <div className="info__panel">
          <div className="search">
            <SearchIcon fontSize="medium" className="icon" />
            <input
              type="text"
              placeholder="введіть текст..."
              onChange={(event) => handleSearch(event)}
              value={search}
            />
          </div>
          <div className="categories">
            <p className="title">Категорії</p>
            <ul className="categories__list">
              {categoriesList.map((item) => {
                return (
                  <li
                    className={
                      activeCategory === item.name ? "item active" : "item"
                    }
                    onClick={() => handleActiveCategory(item.name)}
                    key={item.name}
                  >
                    <div>
                      <ImportContactsOutlinedIcon />
                      <span>{item.name}</span>
                    </div>
                    <span>{item.number}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <Link to="https://play.google.com/store/apps/details?id=com.monthreadapp">
            <img
              className="banner_mr"
              src={require(`../../img/MR/1.jpg`)}
              alt="Month Read"
            />
          </Link>
          <div className="popular__posts">
            <p className="title">Популярні статті</p>
            <ul className="posts">
              {articles
                .filter((post) => post.status === "Публиковать")
                .sort((a, b) => b.views - a.views)
                .slice(0, 3)
                .map((item) => {
                  return (
                    <li
                      className="item"
                      key={item.id}
                      onClick={() => handleArticleId(item.id)}
                    >
                      <span>{item.title}</span>
                      <div>
                        <span>{item.time}</span>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="advertising">
            <div className="first_advertising_block">
              <a
                target="_blank"
                rel="nofollow"
                href="https://ad.admitad.com/g/xwkcfsfom5e21f18559943f490de3a/?i=4"
              >
                <img
                  width="200"
                  height="200"
                  border="0"
                  src="https://ad.admitad.com/b/xwkcfsfom5e21f18559943f490de3a/"
                  alt="Megogo UA"
                />
              </a>
            </div>
            <AdSense.Google
              className="adsbygoogle"
              client="ca-pub-5371468088319298"
              slot="6119696344"
              style={{
                display: "inline-block",
                width: "250px",
                height: "200px",
              }}
              format=""
            />
          </div>
        </div>
      </div>
    </>
  );
};
