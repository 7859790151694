import React from 'react';

import './CompareCard.scss';
import IconArrowDown from './IconArrowDown';
import IconArrowUp from './ArrowUp';

export const CompareCard = ({ count, desc }) => {
  return (
    <div
      className='compareCard'
      style={{ background: count > 0 ? "#c7e6d0" : "#ff9690" }}
    >
      <span className='count'>{Math.abs(count)}</span>
      <span className='title' style={{fontWeight: 700}}>{desc}</span>
      <span className='title'>{count > 0 ? "більше" : "меньше"}</span>
      <div className='icon'>
        {count > 0 ? <IconArrowUp /> : <IconArrowDown />}
      </div>
    </div>
  );
};
