import React from "react";
import "./ReadAlready.scss";

export const Books = ({ props }) => {
  return (
    <div className='allBooks'>
      {props.map((item, index) => {
        return (
          <div className='allBooks__card' key={index}>
            <div className='card__image'>
              <img src={require(`../../img/AllBooks/${item.id}.jpg`)} alt='' />
            </div>
            <div className='card__text'>
              <div className='card__text__month'>{item.name}</div>
              {/* <a
                href={item.link}
                target='_blank'
                rel='noreferrer'
                style={{ color: "white" }}
              >
                <span>
                  <i className='fa-solid fa-cart-plus'></i>
                </span>
              </a> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};
