export const menuItems = [
  { name: "приєднатися", link: "/", id: "#reg" },
  { name: "топ книг", link: "/", id: "#top" },
  { name: "F.A.Q", link: "/faq", id: "/faq" },
  { name: "статистика", link: "/", id: "#counter" },
  { name: "статті", link: "/articles", id: "/articles" },
];

export const menuItemsFaq = [
  { name: "на головну сторінку", link: "/", id: "/" },
  { name: "статті", link: "/articles", id: "/articles" },
];

export const menuItemsArticles = [
  { name: "на головну сторінку", link: "/", id: "/" },
  { name: "F.A.Q", link: "/faq", id: "/faq" },
];

export const menuItemsArticle = [
  { name: "на головну сторінку", link: "/", id: "/" },
  { name: "F.A.Q", link: "/faq", id: "/faq" },
  { name: "статті", link: "/articles", id: "/articles" },
];
