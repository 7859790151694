import React, { useEffect } from "react";
import { Footer } from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import { FaqAll } from "./FaqAll";
import { NavBar } from "../../components/NavBar/NavBar";
import { menuItemsFaq } from "../../assets/MenuItems";

import "./FaqPage.scss";
export const FaQPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='faqPage'>
      <div className='content'>
        <NavBar menuItems={menuItemsFaq} />
        <Helmet>
          <meta charSet='utf-8' />
          <meta name='description' content='FAQ' />
          <title>FAQ</title>
          <meta http-equiv='X-UA-Compatible' content='IE=edge' />
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1.0'
          />
        </Helmet>
        <FaqAll />
      </div>
      <div className='footer'>
        <Footer />
      </div>
    </div>
  );
};
