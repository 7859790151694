import React, { useState, useCallback } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../../../utils/fire";
import ReactMarkdown from "react-markdown";
import SimpleMDE from "react-simplemde-editor";
import { useNavigate } from "react-router";

import "easymde/dist/easymde.min.css";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { HeaderAdmin } from "../../../../components/HeaderAdmin/HeaderAdmin";

import "./AddArticle.scss";

export const AddArticle = ({ toggleArticle, setToggleArticle }) => {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [text, setText] = useState("");
  const [chapter, setChapter] = useState("");
  const [status, setStatus] = useState("Публиковать");

  const navigate = useNavigate();

  const articleRef = collection(db, "Articles");

  const onChangeText = useCallback((value) => {
    setText(value);
  }, []);

  const getTime = () => {
    return new Date().toJSON().slice(0, 10).split("-").reverse().join(".");
  };

  const createUser = async () => {
    await addDoc(articleRef, {
      text,
      image,
      title,
      chapter,
      time: getTime(),
      status,
      views: 0,
      articleId: Date.parse(new Date()),
    });
    setToggleArticle(!toggleArticle);

    navigate(`/admin/articles`);
  };

  const changeChapter = (chapter) => {
    setChapter(chapter);
  };

  const changeStatus = (status) => {
    setStatus(status);
  };

  return (
    <section className='AddArticle'>
      <HeaderAdmin />
      <div className='main'>
        <div className='sidebar'>
          <Sidebar />
        </div>
        <div className='content'>
          <div>
            <h2>Create article</h2>
            <div className='form-group'>
              <input
                type='text'
                className='form-control'
                placeholder='Title'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />

              <form>
                <select
                  onChange={(event) => changeChapter(event.target.value)}
                  className='select'
                  value={chapter}
                >
                  <option value='Новини'>Новини</option>
                  <option value='Підбірки'>Підбірки</option>
                  <option value="Інтерв'ю">Інтерв'ю</option>
                  <option value='Навколокнижкове'>Навколокнижкове</option>
                </select>
                <select
                  onChange={(event) => changeStatus(event.target.value)}
                  className='select'
                  value={status}
                >
                  <option value='Публиковать'>Публиковать</option>
                  <option value='На модерации'>На модерации</option>
                </select>
              </form>

              <input
                type='text'
                className='form-control'
                placeholder='Image'
                value={image}
                onChange={(e) => setImage(e.target.value)}
              />
            </div>
            <SimpleMDE value={text} onChange={onChangeText} />
            <button onClick={createUser}>Создать</button>
          </div>
          <div className='text'>
            <p>{title}</p>
            <p>{chapter}</p>
            <p>Статус: {status}</p>
            <img src={image} alt='' />
            <ReactMarkdown>{text}</ReactMarkdown>
          </div>
        </div>
      </div>
    </section>
  );
};
