import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../../../utils/fire";
import { useNavigate } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";

import "./ArticlesList.scss";
import { HeaderAdmin } from "../../../../components/HeaderAdmin/HeaderAdmin";
import Sidebar from "../../../../components/Sidebar/Sidebar";

export const ArticlesList = ({ articles, toggleArticle, setToggleArticle }) => {
  const navigate = useNavigate();

  const getUpdate = async (id) => {
    navigate(`edit/${id}`);
  };

  const onClickClear = (id) => {
    if (window.confirm("Точно удалить?")) {
      delPost(id);
    }
  };

  const delPost = async (id) => {
    const docRef = doc(db, "Articles", id);
    await deleteDoc(docRef);
    await setToggleArticle(!toggleArticle);
  };

  return (
    <section className='articleList'>
      <HeaderAdmin />
      <div className='main'>
        <div className='sidebar'>
          <Sidebar />
        </div>
        <div className='articles'>
          {articles.length === 0 ? (
            <p>No articles</p>
          ) : (
            articles
              .sort((a, b) => b.articleId - a.articleId)
              .map((article) => (
                <div key={article.id} className='article'>
                  <img src={article.image} alt='' className='img' />
                  <div>
                    <div>{article.title}</div>
                    <p>#{article.chapter}</p>
                    <p>{article.status}</p>
                    <div className='chapter__and__views'>
                      <p>
                        <VisibilityIcon />
                        {article.views}
                      </p>
                    </div>
                    <div className='buttons'>
                      <button onClick={() => getUpdate(article.id)}>
                        Edit
                      </button>
                      <button onClick={() => onClickClear(article.id)}>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))
          )}
        </div>
      </div>
    </section>
  );
};
