import "./Upload.scss";
import { Button } from "./Button/Button";
import { useEffect, useRef, useState } from "react";
import { Preview } from "./Preview/Preview";
import { ProgressBar } from "./ProgressBar/ProgressBar";
import { storage } from "../../utils/fire";
import { v4 as uuidv4 } from "uuid";
import {
  ref,
  listAll,
  getDownloadURL,
  getMetadata,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";

export const Upload2 = () => {
  const inputRef = useRef(null);
  const [images, setImages] = useState([]);
  const getUploadedImages = async () => {
    const listRef = ref(storage, "books/");
    const { items } = await listAll(listRef);
    let currentImages = [];
    for (const itemRef of items) {
      const url = await getDownloadURL(itemRef);
      const metaData = await getMetadata(itemRef);
      currentImages.push({
        name: metaData.name,
        size: metaData.size,
        url: url,
        ref: itemRef,
        id: metaData.customMetadata.id,
      });
    }
    setImages(currentImages);
  };
  useEffect(() => {
    getUploadedImages();
  }, []);
  const handleSelect = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setImages((prev) => {
          return [
            ...prev,
            {
              name: file.name,
              size: file.size,
              url: fileReader.result,
              ref: null,
              file,
              id: uuidv4(),
            },
          ];
        });
      };
      fileReader.readAsDataURL(file);
    });
  };
  const handleDelete = async (image) => {
    if (image.ref) {
      await deleteObject(image.ref);
    }
    setImages((prev) => {
      return prev.filter((_image) => _image.id !== image.id);
    });
  };
  const handleUpload = () => {
    for (const image of images) {
      if (!image.ref) {
        image.loaded = 0;
        const storageRef = ref(storage, "books/" + image.name);
        const uploadTask = uploadBytesResumable(storageRef, image.file, {
          customMetadata: {
            id: image.id,
          },
        });
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            setImages((prev) => {
              return prev.map((_image) => {
                let temp;
                if (_image.id === image.id) {
                  temp = {
                    ..._image,
                    loaded: snapshot.bytesTransferred,
                  };
                }
                if (
                  _image.id === image.id &&
                  snapshot.bytesTransferred === snapshot.totalBytes
                ) {
                  temp = { ...temp, ref: storageRef };
                }
                return temp || _image;
              });
            });
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }
  };
  const getPercentage = () => {
    const filtered = images.filter((image) => image.loaded);
    if (!filtered.length) {
      return 0;
    }
    const currentSize = filtered.reduce((sum, image) => {
      return sum + image.loaded;
    }, 0);
    if (!currentSize) {
      return 0;
    }
    const maxSize = filtered.reduce((sum, image) => {
      return sum + image.size;
    }, 0);
    if (currentSize === maxSize) {
      setImages((prev) => {
        return prev.map((_image) => ({
          ..._image,
          loaded: undefined,
        }));
      });
      return 0;
    }
    return (currentSize * 100) / maxSize;
  };
  return (
    <div className='Upload'>
      <div className='buttons'>
        <input
          type='file'
          accept='image/*'
          onChange={handleSelect}
          multiple
          ref={inputRef}
          className='input'
        />
        <Button
          disabled={Boolean(getPercentage())}
          onClick={() => inputRef.current.click()}
        >
          Выбрать
        </Button>
        <Button
          disabled={Boolean(getPercentage())}
          onClick={handleUpload}
          theme='primary'
        >
          Загрузить
        </Button>
      </div>
      <ProgressBar percentage={getPercentage()} />
      {images.length > 0 ? (
        <div className='imagesWrapper'>
          {images.map((image) => {
            return (
              <Preview key={image.id} onDelete={handleDelete} image={image} />
            );
          })}
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>Нет данных</div>
      )}
    </div>
  );
};
