import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { HeaderAdmin } from "../../../components/HeaderAdmin/HeaderAdmin";
import axios from "axios";

import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../utils/fire";

import "./InfoAboutBooks.scss";

export const InfoAboutBooks = () => {
  const [data, setData] = useState(null);

  const uploadDataFromGoogle = () => {
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbyertAV7LqhKo7hTGPU99yihzfr_4dzFNXbPCeXx9Guk5iOIj-VY0WPomAwaAv-KCGa6g/exec`
      )
      .then((inCommmingData) => {
        setData(inCommmingData.data.AboutBooks);
      });
  };

  const uploadDataOnFirebase = async () => {
    await updateDoc(doc(db, "month-stat", "statistics"), { aboutBooks: data });
  };

  useEffect(() => {
    //console.log(data);
  }, [data]);

  return (
    <section className='uploadPanel'>
      <HeaderAdmin />
      <div className='main'>
        <div className='slider'>
          <Sidebar />
        </div>
        <div className='content'>
          <div className='InfoAboutBooks'>
            <button onClick={uploadDataFromGoogle}>
              Загрузить данные с Google Doc
            </button>
            <button onClick={uploadDataOnFirebase} disabled={!data}>
              Загрузить данные на FireBase
            </button>
          </div>

          {data &&
            data.map((item, index) => {
              return (
                <div className='card' key={index * Math.random()}>
                  <p>{item.number}</p>
                  <p>{item.aboutBook}</p>
                  <p>{item.genre}</p>
                  <p>{item.info}</p>
                  <p>{item.repeat1}</p>
                  <p>{item.repeat2}</p>
                  <p>{item.repeat3}</p>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};
