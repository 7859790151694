import React from "react";

import AdSense from "react-adsense";
import style from "./Reviews.module.scss";

export const Reviews = () => {
  return (
    <div className={style.review}>
      <div className={style.advertising}>
        <AdSense.Google
          className='adsbygoogle'
          client='ca-pub-5371468088319298'
          slot='6119696344'
          style={{
            display: "inline-block",
            width: "250px",
            height: "200px",
            margin: "5px",
          }}
          format=''
        />
        <AdSense.Google
          className='adsbygoogle'
          client='ca-pub-5371468088319298'
          slot='6119696344'
          style={{
            display: "inline-block",
            width: "250px",
            height: "200px",
            margin: "5px",
          }}
          format=''
        />
      </div>
    </div>
  );
};
