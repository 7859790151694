import React from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";

import "./DashBoard.scss";
import { HeaderAdmin } from "../../../components/HeaderAdmin/HeaderAdmin";

export const DashBoard = () => {
  return (
    <section className='dashboard'>
      <HeaderAdmin />
      <div className='main'>
        <div className='slider'>
          <Sidebar />
        </div>
        <div className='content'></div>
      </div>
    </section>
  );
};
