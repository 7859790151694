import { motion } from 'framer-motion';
import { staggerContainer, textContainer, textVariant2 } from '../../utils/motion';

import './TitleText.scss';

export const TitleText = ({ title, text }) => (
  <section className="titleText">
    <motion.div
      className="text_info"
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}>
      <motion.p variants={textContainer} className="text_info_title">
        {Array.from(title).map((letter, index) => (
          <motion.span variants={textVariant2} key={index}>
            {letter === ' ' ? '\u00A0' : letter}
          </motion.span>
        ))}
      </motion.p>
    </motion.div>
    <motion.div
      className="text_info"
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}>
      <motion.p variants={textContainer} className="text_info_title_text">
        {Array.from(text).map((letter, index) => (
          <motion.span variants={textVariant2} key={index}>
            {letter === ' ' ? '\u00A0' : letter}
          </motion.span>
        ))}
      </motion.p>
    </motion.div>
  </section>
);

export const FaqText = ({ text }) => (
  <section className="titleText">
    <motion.div
      className="text_info"
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}>
      <motion.p variants={textContainer} className="text_info_title">
        {Array.from(text).map((letter, index) => (
          <motion.span variants={textVariant2} key={index}>
            {letter === ' ' ? '\u00A0' : letter}
          </motion.span>
        ))}
      </motion.p>
    </motion.div>
  </section>
);
