import React from 'react';
import { useNavigate } from 'react-router-dom';

import './SingleArticle.scss';
export const SingleArticle = ({ article }) => {
  const navigate = useNavigate();

  const handleArticleId = (id) => {
    navigate(`/articles/${id}`);
  };

  return (
    <div className="singleArticles_block">
      <div className="singleArticle">
        <div className="image__conteiner">
          <img src={article.image} alt="" className="image" />
        </div>
        <div className="info">
          <div className="title" onClick={() => handleArticleId(article.id)}>
            {article.title}
          </div>
          <div className="info__footer">
            <span className="chapter">#{article.chapter}</span>
            <div className="info__time__and__button">
              <span>{article.time}</span>
              <button className="singleArticle_btn" onClick={() => handleArticleId(article.id)}>
                читати
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
