import React from 'react';

import './UserCard.scss';
export const UserCard = ({ color, count, desc }) => {
  return (
    <div className="userCard" style={{ background: `${color}` }}>
      <span className="title">{desc}:</span>
      <span className="count">{count}</span>
    </div>
  );
};
