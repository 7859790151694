import React, { useState, useEffect } from "react";
import "./Data.scss";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../utils/fire";

export const Data2 = () => {
  const [formData, setFormData] = useState({
    alreadyRead: {
      input1: "",
      input2: "",
      input3: "",
    },
    thisMonthRead: {
      input1: "",
    },
    topBooks: {
      input1: "",
      input2: "",
    },
  });

  const [oldData, setOldData] = useState({
    alreadyRead: {
      input1: "",
      input2: "",
      input3: "",
    },
    thisMonthRead: {
      input1: "",
    },
    topBooks: {
      input1: "",
      input2: "",
    },
  });
  const [toggle, setToggle] = useState(true);

  const handleForm1Change = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      alreadyRead: {
        ...formData.alreadyRead,
        [name]: value,
      },
    });
  };

  const handleForm2Change = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      thisMonthRead: {
        ...formData.thisMonthRead,
        [name]: value,
      },
    });
  };

  const handleForm3Change = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      topBooks: {
        ...formData.topBooks,
        [name]: value,
      },
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    await updateDoc(doc(db, "month-stat", "statistics"), formData);
    setToggle(false);
    setFormData({
      alreadyRead: {
        input1: "",
        input2: "",
        input3: "",
      },
      thisMonthRead: {
        input1: "",
      },
      topBooks: {
        input1: "",
        input2: "",
      },
    });
    getOldStatistics();
  };

  const getOldStatistics = async () => {
    const userRef = doc(db, "month-stat", "statistics");

    try {
      const statistics = await getDoc(userRef);
      let doc = statistics.data();
      setOldData(doc);
      setToggle(false);
    } catch (error) {
      toast.error("Помилка при авторизації, спробуйте пізніше");
    }
  };

  useEffect(() => {
    getOldStatistics();
  }, [toggle]);

  return (
    <div className='Data'>
      <form onSubmit={handleSubmit}>
        <div className='all_forms all_forms2'>
          <div className='first_forms_block'>
            <div className='form'>
              <p className='form__title'>ВЖЕ ЧИТАЛИ</p>
              <div className='item'>
                <label htmlFor='alreadyRead-input1'>
                  Внесено: {oldData?.alreadyRead.input1}
                </label>
                <input
                  type='text'
                  id='alreadyRead-input1'
                  name='input1'
                  value={formData.alreadyRead.input1}
                  onChange={handleForm1Change}
                />
              </div>
              <div className='item'>
                <label htmlFor='alreadyRead-input2'>
                  Внесено: {oldData?.alreadyRead.input2}
                </label>
                <input
                  type='text'
                  id='alreadyRead-input2'
                  name='input2'
                  value={formData.alreadyRead.input2}
                  onChange={handleForm1Change}
                />
              </div>
              <div className='item'>
                <label htmlFor='alreadyRead-input3'>
                  Внесено: {oldData?.alreadyRead.input3}
                </label>
                <input
                  type='text'
                  id='alreadyRead-input3'
                  name='input3'
                  value={formData.alreadyRead.input3}
                  onChange={handleForm1Change}
                />
              </div>
            </div>
            <div className='form'>
              <p className='form__title'>Топ 3 книг</p>
              <div className='item'>
                <label htmlFor='statAll-input1'>
                  Внесено: {oldData?.topBooks.input1}
                </label>
                <input
                  type='text'
                  id='statAll-input1'
                  name='input1'
                  value={formData.topBooks.input1}
                  onChange={handleForm3Change}
                />
              </div>
              <div className='item'>
                <label htmlFor='statAll-input2'>
                  Внесено: {oldData?.topBooks.input2}
                </label>
                <input
                  type='text'
                  id='statAll-input2'
                  name='input2'
                  value={formData.topBooks.input2}
                  onChange={handleForm3Change}
                />
              </div>
            </div>
          </div>
          <div className='form'>
            <p className='form__title'>ЦЬОГО МІСЯЦЯ ЧИТАЄМО</p>
            <div>
              <label htmlFor='thisMonthRead-input1'>
                Внесено: {oldData?.thisMonthRead.input1}
              </label>
              <input
                type='text'
                id='thisMonthRead-input1'
                name='input1'
                value={formData.thisMonthRead.input1}
                onChange={handleForm2Change}
                className='this_read_input'
              />
            </div>
          </div>
        </div>
        <button type='submit' className='button'>
          Отправить
        </button>
      </form>
    </div>
  );
};
