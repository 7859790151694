import React from 'react';

import './Skeleton.scss';
import str from '../../img/Statistics/str.png';
import book from '../../img/Statistics/book.png';
import vidguk from '../../img/Statistics/vidguk.png';

export const Skeleton = () => {
  return (
    <div className="statistics ">
      <div className="container stat__list">
        <div className="stat__item">
          <div className="stat__item-logo">
            <img src={str} alt="" />
          </div>
          <div className="stat__item-text">
            <div className="stat__item-number">
              <span></span>
              <span></span>
            </div>
            <p className="stat__item-desc"></p>
          </div>
        </div>
        <div className="stat__item stat__item-border">
          <div className="stat__item-logo">
            <img src={book} alt="" />
          </div>
          <div className="stat__item-text">
            <div className="stat__item-number">
              <span></span>
              <span></span>
            </div>
            <p className="stat__item-desc"></p>
          </div>
        </div>
        <div className="stat__item">
          <div className="stat__item-logo">
            <img src={vidguk} alt="" />
          </div>
          <div className="stat__item-text">
            <span className="stat__item-number">
             
            </span>
            <p className="stat__item-desc"></p>
          </div>
        </div>
      </div>
    </div>
  );
};
