import React from 'react';
import { Link } from 'react-router-dom';

import './Button.scss';
export const Button = ({ text, href }) => {
  return (
    <div>
      <Link className="button" to={href} style={{ color: 'white' }}>
        {text}
      </Link>
    </div>
  );
};
