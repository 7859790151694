import { Swiper, SwiperSlide } from "swiper/react";
import { IoBookSharp } from "react-icons/io5";
import { IoBookOutline } from "react-icons/io5";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./BooksPerYear.scss";

import { EffectCoverflow, Pagination, Navigation } from "swiper";
import SwiperCore from "swiper/core";

SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

let data = [
  {
    name: "Гіпотеза кохання",
    genre: "Ромком",
    info: "Маленький експеримент виходить з-під контролю, а теорія романтики розбивається на друзки...",
    publishingHouse: "Vivat",
    rating: [
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookOutline />,
    ],
    number: 1,
    link: "",
  },
  {
    name: "Різдво з червоним кардиналом",
    genre: "Роман",
    info: "Одного дня Освальд зазирає до місцевої крамнички, у якій мешкає пташка...",
    publishingHouse: "КСД",
    rating: [
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
    ],
    number: 2,
    link: "",
  },
  {
    name: "Танці з кістками",
    genre: "Трилер",
    info: "Проста, на перший погляд, історія про патологоанатома-невдаху.",
    publishingHouse: "Віхола",
    rating: [
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
    ],
    number: 3,
    link: "",
  },
  {
    name: "Казка",
    genre: "Фентезі",
    info: "Це була б звичайнісінька історія, якби не загадковий сарай біля будинку містера Боудітча...",
    publishingHouse: "КСД",
    rating: [
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookOutline />,
    ],
    number: 4,
    link: "",
  },
  {
    name: "Я бачу вас цікавить пітьма",
    genre: "Детектив",
    info: "У цьому проклятому селищі, де все по колу і всі живуть життям, яке ненавидять, розслідування постійно заходить у глухий кут.",
    publishingHouse: "ВСЛ",
    rating: [
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
    ],
    number: 5,
    link: "",
  },
  {
    name: "Іспанський любовний обман",
    genre: "Ромком",
    info: "За чотири тижні Каталіна має бути на сестриному весіллі. А отже, має саме чотири тижні, щоб знайти супутника й шугнути до сонячної Іспанії.",
    publishingHouse: "Vivat",
    rating: [
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookOutline />,
    ],
    number: 6,
    link: "",
  },
  {
    name: "Пляжне чтиво",
    genre: "Роман",
    info: "Поки вона виписує свої «Довго й щасливо», він убиває всіх персонажів.",
    publishingHouse: "ARTBOOKS",
    rating: [
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookOutline />,
    ],
    number: 7,
    link: "",
  },
  {
    name: "Віолета",
    genre: "Роман",
    info: "Віолета дель Вальє народилася 1920 морового року, у час, коли інфлюенца й війна, а потім велика депресія назавжди змінили людське життя навколо й далеко за межами її країни",
    publishingHouse: "Анетти Антоненко",
    rating: [
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookOutline />,
    ],
    number: 8,
    link: "",
  },
  {
    name: "Драбина",
    genre: "Роман",
    info: "Толік щойно здійснив головну мрію свого життя: купив будинок, де все буде так, як хоче лише він. Однак війна перевернула все, і на голову йому звалилась рідня.",
    publishingHouse: "ВСЛ",
    rating: [
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookOutline />,
    ],
    number: 9,
    link: "",
  },
  {
    name: "Колонія",
    genre: "Фантастика",
    info: "Людство на Землі ще не оговталося від хвороби клодис, що призвела до найбільшої за пів століття пандемії, коли з’являється новий патоген...",
    publishingHouse: "Бородатий Тамарин",
    rating: [
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
      <IoBookSharp />,
    ],
    number: 10,
    link: "",
  },
];

export const BooksPerYear = () => {
  return (
    <div className='BooksPerYear'>
      <p className='title_block'>Топ книг минулого року</p>
      <Swiper
        effect={"coverflow"}
        loop={true}
        navigation={true}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 90,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={{ clickable: true }}
        modules={[EffectCoverflow, Pagination]}
        className='book_slider'
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide key={item.number * index}>
              <div className='book'>
                <img
                  src={require(`../../img/BooksPerYear/k${item.number}.jpg`)}
                  className='image'
                  alt=''
                />
                <div
                  className={
                    item.number === 10 ? "number last_number" : "number"
                  }
                >{`#${item.number}`}</div>
                <div className='publishingHouse'>{item.publishingHouse}</div>
              </div>
              <div className='text'>
                <div className='desc'>
                  <div className='head'>
                    <div className='name'>{item.name}</div>
                    <div className='genre'>{item.genre}</div>
                  </div>
                  <p className='info'>{item.info}</p>
                  <div className='rating'>
                    <p className='title'>Рейтинг читачів:</p>
                    <div className='icons'>
                      {item.rating.map((item, index) => (
                        <div className='icon' key={index}>
                          {item}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div className='btn1'>
                  <button>
                    <a target='_blank' href={item.link} alt='' rel='noreferrer'>
                      Читати більше
                    </a>
                  </button>
                </div> */}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
