import { NavBar } from "../../components/NavBar/NavBar";
import { Footer } from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

import "./Privacy.scss";
import { menuItemsArticle } from "../../assets/MenuItems";
const Privacy = () => {
  return (
    <section className='Privacy'>
      <div className='privacy__header'>
        <NavBar menuItems={menuItemsArticle} />
      </div>
      <div className='privacy__content'>
        <p className='title'>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</p>
        <p className='date'>в редакції від 01.03.2023 року</p>
        <p>
          Ця Політика конфіденційності визначає загальні умови обробки та
          захисту персональних даних Користувачів, які збираються в процесі
          отримання ними послуг, що надаються Адміністратором Сайту&nbsp;
          <Link to='https://monthread.pp.ua/'>https://monthread.pp.ua/</Link>
        </p>

        <ol className='list'>
          <li className='items'>
            Загальні положення
            <ul>
              <li>
                1.1. Перед здійсненням Сайту Користувач зобов’язаний
                ознайомитись із положеннями цієї Політики конфіденційності.
              </li>
              <li>
                1.2. Згода Користувача на обробку його персональних даних може
                виражатись у конклюдентних діях після того, як Користувач
                повинен був ознайомитись із цією Політикою конфіденційності.
              </li>
              <li>
                1.3. Користувач повинен негайно припинити використання Сайту,
                якщо він не погоджується з умовами цієї Політики
                конфіденційності.
              </li>
              <li>
                1.4. Володільцем та розпорядником персональних даних відповідно
                до цієї Політки конфіденційності є Виконавець.
              </li>
            </ul>
          </li>
          <li className='items'>
            Визначення понять і їх тлумачення
            <ul>
              <li>
                2.1. Терміни та поняття, що використовуються у цій Політиці
                конфіденційності, вживаються у значеннях наведених нижче:
                <ul>
                  <li>
                    <b>Сайт</b> – сукупність даних (текстів, графічних і
                    дизайнерських елементів, фото, відео та інших результатів
                    інтелектуальної діяльності, комп’ютерних програм), що
                    пов’язані між собою, містяться в інформаційній системі, та
                    забезпечують доступність цієї інформації для кінцевих
                    споживачів та розміщуються за адресою:
                    https://monthread.pp.ua/.
                  </li>
                  <li>
                    <b>Користувач</b> – фізична особа, яка використовує Сайт.
                  </li>
                  <li>
                    <b>Адміністратор Сайту</b> - володілець та розпорядник
                    персональних даних Користувачів.
                  </li>
                </ul>
              </li>
              <li>
                2.2. Заголовки у цій Політиці конфіденційності наводяться лише
                для зручності і не впливають на тлумачення пунктів Політики
                конфіденційності.
              </li>
              <li>
                У випадку, якщо у цій Політиці конфіденційності використовуються
                терміни і поняття, не визначені цим розділом Політики
                конфіденційності, то тлумачення такого терміну або поняття
                відбувається відповідно до тексту цієї Політики.
              </li>
              <li>
                2.3. У випадку відсутності однозначного тлумачення терміну або
                поняття, необхідно тлумачити це поняття, виходячи з правил
                розуміння таких термінів у контексті цієї Політики відповідно до
                законодавства України, Академічного тлумачного словника
                української мови, а у разі їх відсутності – відповідно до мережі
                «Інтернет».
              </li>
            </ul>
          </li>
          <li className='items'>
            Дані, які можуть збиратися
            <ul>
              <li>
                3.1. До інформації про Користувача, яка може збиратися
                відповідно до цієї Політики конфіденційності, відноситься
                інформація, отримана під час використання Сайту, зокрема:
                <ul>
                  <li>- файли Cookies</li>
                  <li>- IP-адреса Користувача;</li>
                  <li>- тип використовуваного пристрою;</li>
                  <li>- час доступу</li>
                  <li>- поведінка Користувача на Сайті. </li>
                </ul>
              </li>
              <li>
                3.2. Виконавець може автоматично збирати неособисту інформацію
                Користувача, тобто таку, що не дає можливості прямо чи
                опосередковано ідентифікувати Користувача, зокрема про тип
                браузера Користувача або інформацію про сайт, з якого Користувач
                перейшов на Сайт.
              </li>
            </ul>
          </li>
          <li className='items'>
            Мета обробки та строки зберігання персональних даних
            <ul>
              <li>
                4.1. Інформація про файли Cookies, IP-адресу Користувача, тип
                використовуваного пристрою, час доступу, поведінку Користувача
                на Сайті збирається для розробки нових послуг, адаптації
                маркетингових і рекламних заходів, що пропонується на Сайті або
                сервісах третіх осіб, та контролю загальної та індивідуальної
                активності Користувачів.
              </li>
              <li>
                4.2. Така інформація зберігається протягом трьох років із
                моменту останнього відвідування Сайту Користувачем.
              </li>
            </ul>
          </li>
          <li className='items'>
            Права Користувача
            <ul>
              <li>
                5.1. Користувач має право:
                <li>
                  5.1.1. знати про джерела збирання, місцезнаходження своїх
                  персональних даних, мету їх обробки, місцезнаходження або
                  місце проживання (перебування) володільця чи розпорядника
                  персональних даних або дати відповідне доручення щодо
                  отримання цієї інформації уповноваженим ним особам, крім
                  випадків, встановлених законом;
                </li>
                <li>
                  5.1.2. отримувати інформацію про умови надання доступу до
                  персональних даних, зокрема інформацію про третіх осіб, яким
                  передаються його персональні дані;
                </li>
                <li>5.1.3. на доступ до своїх персональних даних;</li>
                <li>
                  5.1.4. пред’являти вмотивовану вимогу володільцю персональних
                  даних із запереченням проти обробки своїх персональних даних;
                </li>
                <li>
                  5.1.5. пред'являти вмотивовану вимогу щодо зміни або знищення
                  своїх персональних даних будь-яким володільцем та
                  розпорядником персональних даних, якщо ці дані обробляються
                  незаконно чи є недостовірними;
                </li>
                <li>
                  5.1.6. на захист своїх персональних даних від незаконної
                  обробки та випадкової втрати, знищення, пошкодження у зв'язку
                  з умисним приховуванням, ненаданням чи несвоєчасним їх
                  наданням, а також на захист від надання відомостей, що є
                  недостовірними чи ганьблять честь, гідність та ділову
                  репутацію фізичної особи;
                </li>
                <li>
                  5.1.7. звертатися із скаргами на обробку своїх персональних
                  даних до Уповноваженого Верховної ради України з прав людини
                  або до суду;
                </li>
                <li>
                  5.1.8. застосовувати засоби правового захисту в разі порушення
                  законодавства про захист персональних даних;
                </li>
                <li>
                  5.1.9. вносити застереження стосовно обмеження права на
                  обробку своїх персональних даних під час надання згоди;
                </li>
                <li>5.1.10. відкликати згоду на обробку персональних даних;</li>
                <li>
                  5.1.11. знати механізм автоматичної обробки персональних
                  даних;
                </li>
                <li>
                  5.1.12. на захист від автоматизованого рішення, яке має для
                  нього правові наслідки.
                </li>
              </li>
              <li>
                5.2. Користувач, який має намір реалізувати свої права,
                передбачені пунктом 5.1 цієї Політики конфіденційності,
                направляє свою вимогу на адресу електронної пошти:
                monthofread@gmail.com. Така вимога розглядається Виконавцем
                протягом 30 днів із моменту отримання.
              </li>
            </ul>
          </li>
          <li className='items'>
            Доступ до персональних даних третіми особами
            <ul>
              <li>
                6.1. Персональні дані Користувачів можуть бути передані третім
                особам у таких випадках:
                <li>6.1.1. за письмовою згодою Користувача;</li>
                <li>
                  6.1.2. у випадках, передбачених чинним законодавством України
                  та цією Політикою конфіденційності;
                </li>
                <li>
                  6.1.3. у випадку необхідності передачі даних партнерам
                  (контрагентам тощо) Виконавця з метою вчинення правочинів
                  (виконання зобов’язань), які пов’язані із Користувачем.
                </li>
              </li>
              <li>
                6.2. Виконавець залишає за собою право надавати знеособлені дані
                будь-яким третім особам.
              </li>
            </ul>
          </li>
          <li className='items'>
            Порядок захисту конфіденційної інформації
            <ul>
              <li>
                7.1. Виконавець здійснює обробку персональних даних із
                застосуванням правових, організаційних та технічних заходів, що
                запобігають випадковим втратам, знищенню та (або) незаконній
                обробці, зокрема незаконного знищення чи доступу до персональних
                даних.
              </li>
              <li>
                7.2. Працівники Виконавця та треті особи, залучені Виконавцем із
                метою надання послуг Користувачу, мають доступ лише до тих
                персональних даних, які необхідні їм в процесі виконання своїх
                обов’язків.
              </li>
              <li>
                7.3. Видалення та знищення персональних даних Користувачів
                здійснюється у спосіб, що виключає подальшу можливість
                поновлення таких персональних даних.
              </li>
            </ul>
          </li>
          <li className='items'>
            Інші положення
            <ul>
              <li>
                8.1. Виконавець має право в односторонньому порядку змінювати
                умови цієї Політики конфіденційності, не повідомляючи про це
                Користувача. Зміни до Політики вступають у силу з моменту їх
                розміщення на Сайті.
              </li>
              <li>
                8.2. У разі, якщо будь-яке з положень цих Правил буде визнано
                будь-яким компетентним державним органом України недійсним, це
                не вплине на дійсність або можливість виконання будь-яких інших
                умов цих Правил, які залишаються повністю дійсними, якщо інше не
                передбачено законодавством України.
              </li>
              <li>
                8.3. Вирішення спорів, які виникають між Сторонами щодо
                виконання цих Правил, здійснюється шляхом переговорів.
              </li>
              <li>
                8.4. Спори між Сторонами, не врегульовані Сторонами шляхом
                переговорів, вирішуються компетентним судом України за
                законодавством України.
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <div className='privacy__footer'>
        <Footer />
      </div>
    </section>
  );
};
export default Privacy;
