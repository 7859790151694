import React, { useState } from "react";
import { Accordin } from "./Accordin";
import { Button } from "../Button/Button";

import "./FaqBlock.scss";
import faq_picture from "../../img/Faq/faq_picture.png";

export const FAQBlock = () => {
  const [active, setActive] = useState("");

  return (
    <div className='container faqBlock'>
      <div className='accordion'>
        <div className='title'>Залишилися питання?</div>
        <Accordin
          title={"Як зареєструватися в марафоні?"}
          content={
            <p>
              Реєстрація в марафон проходить через&nbsp;
              <a
                href='http://t.me/Monthreadbot'
                target='_blank'
                rel='noreferrer'
              >
                Читайбота
              </a>
              . Крім того, щоб ваші дані були зараховані вам необхідно зробити
              репост з постом анонсом марафону в інстаграм до себе в профіль (на
              час воєнного стану ця умова не діє), а також, бути підписаним на
              організатора. В разі, якщо ви дійшли до кінця марафону, але репост
              не робили, то, на жаль, результат зараховано не буде.
            </p>
          }
          active={active}
          setActive={setActive}
        />
        <Accordin
          title={"Що ще вміє бот?"}
          content={
            "В бота можна писати список своїх прочитаних книг, вносити посилання на відгуки в інстаграмі, а також бачити свою статистику за весь період участі в марафоні та отримувати нагороди."
          }
          active={active}
          setActive={setActive}
        />
        <Accordin
          title={"Як рахувати сторінки?"}
          content={
            "Сторінки рахуються за день. Ви читаєте книгу і лише в кінці дня вносите ОДНЕ число, що = сумі прочитаних вами сторінок"
          }
          active={active}
          setActive={setActive}
        />
        <Button text={"ПЕРЕЙТИ ДО ПОВНОГО СПИСКУ ПИТАНЬ"} href='/faq' />
      </div>

      <div className='img'>
        <img src={faq_picture} alt='' />
      </div>
    </div>
  );
};
