import React from "react";

import "./Icons.scss";
export const Icons = () => {
  return (
    <div className='icons'>
      <div className='icon telegram'>
        <div className='toolrip'>Telegram</div>
        <a href='https://t.me/monthread' target='_blank' rel='noreferrer'>
          <span>
            <i className='fa-brands fa-telegram'></i>
          </span>
        </a>
      </div>
      <div className='icon youtube'>
        <div className='toolrip'>YouTube</div>
        <a
          href='https://www.youtube.com/channel/UC3Ek-jI4Ouc1eS-ClYkAp_w'
          target='_blank'
          rel='noreferrer'
        >
          <span>
            <i className='fa-brands fa-youtube'></i>
          </span>
        </a>
      </div>
      <div className='icon instagram'>
        <div className='toolrip'>Instagram</div>
        <a
          href='https://www.instagram.com/lira.voropaeva/'
          target='_blank'
          rel='noreferrer'
        >
          <span>
            <i className='fa-brands fa-instagram'></i>
          </span>
        </a>
      </div>
      <div className='icon facebook'>
        <div className='toolrip'>Facebook</div>
        <a
          href='https://www.facebook.com/groups/monthread'
          target='_blank'
          rel='noreferrer'
        >
          <span>
            <i className='fa-brands fa-facebook'></i>
          </span>
        </a>
      </div>
      <div className='icon bot'>
        <div className='toolrip'>Бот</div>
        <a href='http://t.me/Monthreadbot' target='_blank' rel='noreferrer'>
          <span>
            <i className='fa-solid fa-robot'></i>
          </span>
        </a>
      </div>
      <div className='icon patreon'>
        <div className='toolrip'>Додаток</div>
        <a
          href='https://play.google.com/store/apps/details?id=com.monthreadapp'
          target='_blank'
          rel='noreferrer'
        >
          <span>
            <i className='fa-brands fa-android'></i>
          </span>
        </a>
      </div>
    </div>
  );
};
