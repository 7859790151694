import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCDJGws1DEHW_ylidmnmqpLnYNA9RZyug0",
  authDomain: "month-of-read-authentication.firebaseapp.com",
  projectId: "month-of-read-authentication",
  storageBucket: "month-of-read-authentication.appspot.com",
  messagingSenderId: "654629333634",
  appId: "1:654629333634:web:9362955b8d9f70b374e29b",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyDTunP-8u5115aDG_U8888wpzlq00DRQB8",

//   authDomain: "auth-project-bc579.firebaseapp.com",

//   projectId: "auth-project-bc579",

//   storageBucket: "auth-project-bc579.appspot.com",

//   messagingSenderId: "683114776892",

//   appId: "1:683114776892:web:5c64947422f19947713639",
// };

export const fire = firebase.initializeApp(firebaseConfig, "fire__auth");

export const db = getFirestore(fire);
export const auth = getAuth(fire);

export const googleProvider = new GoogleAuthProvider();
