import React, { useState, useEffect } from "react";
import { HeaderAdmin } from "../../../components/HeaderAdmin/HeaderAdmin";
import Sidebar from "../../../components/Sidebar/Sidebar";
import axios from "axios";

import "./BookSearch.scss";
export const BookSearch = ({ handleLogOut }) => {
  const [value, setValue] = useState("");
  const [search, setSearch] = useState([]);

  const handleValue = (e) => {
    setValue(e);
  };

  useEffect(() => {
    axios
      .get(
        `https://www.googleapis.com/books/v1/volumes?q=${value}&key=AIzaSyDAGYQtU_hSWJwbZD6O_tTehZvP1u5Csqw`
      )
      .then((inCommmingData) => {
        setSearch(inCommmingData.data.items);
      });
  }, [value]);

  return (
    <section className='bookSearch'>
      <HeaderAdmin />
      <div className='main'>
        <div className='sidebar'>
          <Sidebar handleLogOut={handleLogOut} />
        </div>
        <div className='content'>
          <input
            type='text'
            placeholder='search'
            value={value}
            onChange={(e) => handleValue(e.target.value)}
          />

          {search.map((book) => {
            return (
              <div>
                <FindBook book={book} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

const FindBook = ({ book }) => {
  return (
    <div className='findBook'>
      <p>{book.volumeInfo.title}</p>
      {book.volumeInfo.description && <p>{book.volumeInfo.description}</p>}
      {book.volumeInfo.imageLinks.smallThumbnail && (
        <img src={book.volumeInfo.imageLinks.smallThumbnail} alt='' />
      )}
    </div>
  );
};
