import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SingleArticle } from '../SingleArticle/SingleArticle';

import './ArticlesBlock.scss';

export const ArticlesBlock = ({ articles }) => {
  const navigate = useNavigate();

  const handleArticlePage = () => {
    navigate(`/articles`);
  };

  const sortArticles = articles
    .filter((post) => post.status === 'Публиковать')
    .sort((a, b) => b.articleId - a.articleId)
    .slice(0, 3);

  return (
    <div className="articleBlock">
      <div className="articlesWrapper">
        <div className="head">
          <div className="title">Цікаве про книжки</div>
          <button onClick={() => handleArticlePage()}>всі статті</button>
        </div>
        <div className="articles">
          {sortArticles.map((item) => (
            <div className="item" key={item.id}>
              <SingleArticle article={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
