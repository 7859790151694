import "./ProgressBar.scss";

export const ProgressBar = ({ percentage }) => {
  if (!percentage) {
    return null;
  }

  return (
    <div className="ProgressBar">
      <span>{Math.floor(percentage)}%</span>
      <div style={{ width: percentage + "%" }} className="percentage"></div>
    </div>
  );
};
