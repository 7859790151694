import { menuItemsArticle } from "../../assets/MenuItems";
import { NavBar } from "../../components/NavBar/NavBar";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import "./Profile.scss";
import { Button } from "../../components/Button/Button";
export const Profile = () => {
  return (
    <div className='profile'>
      <NavBar menuItems={menuItemsArticle} />
      <section className={"profile__content dark"}>
        <h2>
          <span className='icon'>
            <WarningAmberIcon />
          </span>
          Сторінка на стадії розробки
        </h2>
        <Button text='Повернутись на головну сторінку' href='/' />
      </section>
    </div>
  );
};
