import React from 'react';

import './WithoutGenre.scss';

import pic from '../../img/User/Genre/noGenre.jpg';
export const WithoutGenre = () => {
  return (
    <div className="noGenre">
      <img src={pic} alt="" />
    </div>
  );
};
