import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn, staggerContainer } from '../../utils/motion';

import './RegSteps.scss';
export const RegSteps = () => {
  return (
    <div className="regSteps">
      <div className="picture">
        <img src={require('../../img/regbook2.png')} alt="" />
      </div>

      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}>
        <motion.div variants={fadeIn('left', 'tween', 0.9, 1)} className="text">
          <div className="title">
            <h2>ХОЧЕШ ПРИЙНЯТИ УЧАСТЬ?</h2>
          </div>
          <div className="items">
            <div className="item">
              <div className="item__title">КРОК 1</div>
              <div className="item__text">
                Зареєструйся в читайботі марафону в телеграмі. За допомогою бота ти можеш слідкувати
                за своїми результатами та отримувати відзнаки за свої досягнення.
              </div>
            </div>
            <div className="item">
              <div className="item__title">КРОК 2</div>
              <div className="item__text">
                Читай кожного дня та не забувай вводити дані до бота в кінці дня. Одна сторінка
                краще ніж нічого. За місяць разом з нами ти зможеш нарешті подолати нечитуна.
              </div>
            </div>
            <div className="item">
              <div className="item__title">КРОК 3</div>
              <div className="item__text">
                Читай ті книги, які тобі подобаються. Ми не ставимо жодних обмежень по жанру чи
                автору. Читай детективи чи фентезі, приєднуйся до спільних читань та обговорюй
                книжки з однодумцями, щоб читати було веселіше.
              </div>
            </div>
            <div className="item">
              <div className="item__title">КРОК 4</div>
              <div className="item__text">
                Додавай прочитані книги, пиши відгуки в інстаграмі, стань на крок ближче до
                книжкової спільноти України. А найактивніших читунів місяця буде чекати приємний
                сюрприз.
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};
