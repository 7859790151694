import React from "react";
import { motion } from "framer-motion";
import { fadeIn, staggerContainer } from "../../utils/motion";

import "./Registration.scss";

export const Registration = () => {
  return (
    <div className='registration' id='reg'>
      <div className='reg__text'>
        <div className='reg__text-title'>
          <p>ПРИЄДНУЙСЯ ДО МАРАФОНУ МІСЯЦЬ ЧИТАННЯ</p>
        </div>
        <div className='reg__text-text'>
          <h2>
            Ходить міф, що для читання потрібно багато часу. Але це не правда.
            Читаючи по 10 сторінок в день за місяць можна прочитати книжку. А
            що, якщо читати по 30 хвилин в день кожного дня протягом року?
            Прочитати 20 книжок за рік вже не є чимось недосяжним.
          </h2>
          <h2>
            За останньою статистикою лише 8% українців читають кожного дня. Нас
            тішить, що серед цих 8% є учасники нашого марафону, але ми хочемо,
            щоб цей відсоток зростав кожного року. А краще кожного місяця.
            Впевнені у нас вийде, адже вже понад рік ми мотивуємо на читання, як
            дорослих, так і дітей.
          </h2>
          <h2>
            Ти не випадково завітав на цю сторінку. Приєднуйся і ти до нашої
            команди читунів. Читай кожного дня, слідкуй за своєю статистикою
            читання, а головне отримуй подарунки.
          </h2>
        </div>

        <form action='https://t.me/Monthreadbot' className='reg__text-button'>
          <button className='btn'>ЗАРЕЄСТРУВАТИСЯ</button>
        </form>
      </div>

      <motion.div
        className='reg__photo'
        variants={staggerContainer}
        initial='hidden'
        whileInView='show'
        viewport={{ once: true, amount: 0.25 }}
      >
        <motion.div variants={fadeIn("left", "tween", 0.9, 0.25)}>
          <img src={require("../../img/infobook.png")} alt='get-started.png' />
        </motion.div>
      </motion.div>
    </div>
  );
};
