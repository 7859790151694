import firebase from "firebase/compat/app";
import { getStorage } from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBPoKzonB9f554s56S9id-0Oly7PvcLjlE",
  authDomain: "admin-panel-web-marafon.firebaseapp.com",
  projectId: "admin-panel-web-marafon",
  storageBucket: "admin-panel-web-marafon.appspot.com",
  messagingSenderId: "514580455301",
  appId: "1:514580455301:web:9a3fbb79d0d45d62e5eeec",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyDTunP-8u5115aDG_U8888wpzlq00DRQB8",

//   authDomain: "auth-project-bc579.firebaseapp.com",

//   projectId: "auth-project-bc579",

//   storageBucket: "auth-project-bc579.appspot.com",

//   messagingSenderId: "683114776892",

//   appId: "1:683114776892:web:5c64947422f19947713639",
// };

export const fire = firebase.initializeApp(firebaseConfig);
export const storage = getStorage(fire);
export const db = getFirestore(fire);
