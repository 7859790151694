import { Link } from "react-router-dom";
import "./AlreadyReadBooks.scss";

export const AlreadyReadBooks = ({ alreadyRead, topBooks }) => {
  return (
    <section className='AlreadyReadBooks'>
      <div className='books'>
        <div className='title'>
          <div className='text'>вже читали</div>
          <Link className='read__more' to='/read-already'>
            більше
          </Link>
        </div>
        <div className='books__list'>
          <div className='book'>
            <img
              src='https://firebasestorage.googleapis.com/v0/b/admin-panel-web-marafon.appspot.com/o/books%2Fbook1.png?alt=media'
              alt=''
            />
            {/* <a href={alreadyRead.input1} alt='' target='_blank'>
              більше
            </a> */}
          </div>
          <div className='book'>
            <img
              src='https://firebasestorage.googleapis.com/v0/b/admin-panel-web-marafon.appspot.com/o/books%2Fbook2.png?alt=media'
              alt=''
            />
            {/* <a href={alreadyRead.input2} alt='' target='_blank'>
              більше
            </a> */}
          </div>
          <div className='book'>
            <img
              src='https://firebasestorage.googleapis.com/v0/b/admin-panel-web-marafon.appspot.com/o/books%2Fbook3.png?alt=media'
              alt=''
            />
            {/* <a href={alreadyRead.input3} alt='' target='_blank'>
              більше
            </a> */}
          </div>
        </div>
      </div>
      <div className='books__article'>
        <div className='book'>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/admin-panel-web-marafon.appspot.com/o/books%2Ftop1.png?alt=media'
            className='first__book'
            alt=''
          />
          <img
            src='https://firebasestorage.googleapis.com/v0/b/admin-panel-web-marafon.appspot.com/o/books%2Ftop3.png?alt=media'
            className='second__book'
            alt=''
          />
          <img
            src='https://firebasestorage.googleapis.com/v0/b/admin-panel-web-marafon.appspot.com/o/books%2Ftop2.png?alt=media'
            className='third__book'
            alt=''
          />
        </div>
        <h4>
          <Link to={topBooks.input2} className='link'>
            {topBooks.input1}
          </Link>
        </h4>
      </div>
    </section>
  );
};
