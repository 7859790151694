import React from 'react';

import './Medals.scss';
import { ProgressBar } from '../ProgressBar/ProgressBar';

export const Medals = ({ user }) => {
  const getMedalInfo = (medal, count) => {
    if (count === 1) {
      if (medal === 0) {
        return 0;
      }

      if (medal === 3) {
        return 25;
      }

      if (medal === 6) {
        return 50;
      }

      if (medal === 9) {
        return 75;
      }
      if (medal === 12) {
        return 100;
      }
    }

    if (count === 2) {
      if (medal === 0) {
        return 0;
      }

      if (medal === 50) {
        return 25;
      }

      if (medal === 100) {
        return 50;
      }

      if (medal === 150) {
        return 75;
      }

      if (medal === 200) {
        return 100;
      }
    }

    if (count === 3) {
      if (medal === 0) {
        return 0;
      }

      if (medal === 10) {
        return 25;
      }

      if (medal === 25) {
        return 50;
      }

      if (medal === 50) {
        return 75;
      }

      if (medal === 100) {
        return 100;
      }
    }

    if (count === 4) {
      if (medal === 0) {
        return 0;
      }

      if (medal === 25) {
        return 25;
      }

      if (medal === 50) {
        return 50;
      }

      if (medal === 75) {
        return 75;
      }

      if (medal === 100) {
        return 100;
      }
    }
  };

  return (
    <div className="medalsInfo">
      <p className="title">Твої досягнення</p>
      <div className="medalItems">
        <ProgressBar
          counter={getMedalInfo(user[0].medal_1, 1)}
          count={1}
          medalName={'Книгочитун'}
          className="item"
        />
        <ProgressBar
          counter={getMedalInfo(user[0].medal_2, 2)}
          count={2}
          medalName={'Книгоман'}
          className="item"
        />
        <ProgressBar
          counter={getMedalInfo(user[0].medal_3, 3)}
          count={3}
          medalName={'Відгуковець'}
          className="item"
        />
        <ProgressBar
          counter={getMedalInfo(user[0].medal_4, 4)}
          count={4}
          medalName={'Читоман'}
          className="item"
        />
      </div>
    </div>
  );
};
