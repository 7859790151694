import React from 'react';

import './Accordin.scss';
export const Accordin = ({ title, content, active, setActive }) => {
  return (
    <div className="accordion-item" id="#faq">
      <div
        className={active === title ? 'header active' : 'header'}
        onClick={() => setActive(title)}>
        {title}
      </div>
      <div className={active === title ? 'item-body-active' : 'item-body'}>
        <div className="item-body-content">{content}</div>
      </div>
    </div>
  );
};
