import React from 'react';

import loading from '../../img/User/Loading/loading.gif';

import './loadingUserPage.scss';
export const LoadingUserPage = () => {
  return (
    <div className="loadingUserPage">
      <img src={loading} alt="" />
      <p>Зачекайте, завантажуємо ваші дані...</p>
    </div>
  );
};
