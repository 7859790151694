import "./MonthReadBook.scss";
import blot from "../../img/Home/MonthReadBook/blot.png";

export const MonthReadBook = ({ data }) => {
  return (
    <section className='MonthReadBook'>
      <div className='MonthReadBook__wrapper'>
        <div className='book'>
          <div className='blot'>
            <img src={blot} alt='book picture' />
          </div>
          <div className='book__pic'>
            <img
              src='https://firebasestorage.googleapis.com/v0/b/admin-panel-web-marafon.appspot.com/o/books%2Fmonth.png?alt=media'
              alt='book picture'
            />
          </div>
        </div>
        <div className='text'>
          <p>цього місяця читаємо</p>
          <h5>{data.input1}</h5>
          <button>
            <a href='https://t.me/monthread/265' target='_blank'>
              ЗАРЕЄСТРУВАТИСЯ
            </a>
          </button>
        </div>
      </div>
    </section>
  );
};
