import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../utils/firebase.auth";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { doc, getDoc } from "firebase/firestore";
import { getLogin } from "../redux/slices/authSlice";

const useAuth = () => {
  const [currentUser, setCurrentUser] = useState({});
  const dispatch = useDispatch();

  const authRole = useSelector((state) => state.auth.currentUser);

  const getAuth = async (user) => {
    const userRef = doc(db, "users", user.uid);
    try {
      const userInfo = await getDoc(userRef);
      let userProfile = userInfo.data();
      if (userProfile) {
        dispatch(
          getLogin({
            uid: userProfile.uid,
            displayName: userProfile.displayName,
            email: userProfile.email,
            creationTime: userProfile.creationTime,
            lastSignInTime: userProfile.lastSignInTime,
            photoURL: userProfile.photoURL,
            role: userProfile.role,
          })
        );
      }
    } catch (error) {
      toast.error("Помилка при авторизації, спробуйте пізніше");
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);

        if (!authRole) {
          getAuth(user);
        }
      } else {
        setCurrentUser(null);
      }
    });
  }, []);

  return {
    currentUser,
  };
};
export default useAuth;
