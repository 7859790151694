import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import LoginIcon from "@mui/icons-material/Login";

import "./NavBar.scss";
import { Icons } from "../Icons/Icons";

import { signInWithPopup, signOut } from "firebase/auth";
import { auth, googleProvider, db } from "../../utils/firebase.auth";
import { toast } from "react-toastify";
import { setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import Person2Icon from "@mui/icons-material/Person2";
import { useDispatch, useSelector } from "react-redux";
import { getLogin, getLogout } from "../../redux/slices/authSlice";
import PersonIcon from "@mui/icons-material/Person";
import Modal from "../../components/Modal/Modal";

export const NavBar = ({ menuItems }) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [activeModal, setActiveModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authRole = useSelector((state) => state.auth.currentUser);
  const { currentUser } = useAuth();

  const handleActiveMenu = () => {
    setActiveMenu(!activeMenu);
  };

  const handleClick = (index) => {
    closeFunc();
  };

  const closeFunc = () => {
    setActiveMenu(false);
  };

  const useLogin = async () => {
    const userCredential = await signInWithPopup(auth, googleProvider);
    const user = userCredential.user;

    setActiveMenu(false);
    setActiveModal(false);
    toast.success("Ви авторизувалися на сайті");

    const userRef = doc(db, "users", user.uid);

    try {
      const userInfo = await getDoc(userRef);
      let userProfile = userInfo.data();

      if (userProfile) {
        await updateDoc(doc(db, "users", user.uid), {
          displayName: user.displayName,
          lastSignInTime: user.metadata.lastSignInTime,
        });
        dispatch(
          getLogin({
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            creationTime: user.metadata.creationTime,
            lastSignInTime: user.metadata.lastSignInTime,
            photoURL: user.photoURL,
            role: userProfile.role,
          })
        );
      } else {
        await setDoc(doc(db, "users", user.uid), {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          creationTime: user.metadata.creationTime,
          lastSignInTime: user.metadata.lastSignInTime,
          role: "User",
        });
        dispatch(
          getLogin({
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            creationTime: user.metadata.creationTime,
            lastSignInTime: user.metadata.lastSignInTime,
            photoURL: user.photoURL,
            role: "User",
          })
        );
      }
    } catch (error) {
      toast.error("Помилка при авторизації, спробуйте пізніше");
    }
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        setActiveMenu(false);
        navigate("/");
        toast.success("Ви вийшли з особистого кабінету");
      })
      .catch((err) => {});
    dispatch(getLogout());
  };

  return (
    <div className='navBar'>
      <header>
        <Link to='/'>
          <img
            className='logo'
            src={require("../../img/logo.png")}
            alt='logo'
          />
        </Link>
        <nav className={activeMenu ? "menu" : "navigation"}>
          {menuItems.map((item, index) => {
            return (
              <HashLink
                smooth
                to={item.id}
                className='item'
                key={index}
                onClick={() => handleClick(index)}
              >
                {item.name}
              </HashLink>
            );
          })}

          <div className='log-in-web'>
            {currentUser ? (
              <>
                <img
                  src={currentUser.photoURL}
                  alt='user'
                  className='user__image'
                />
                <ul className='dropdown-menu'>
                  <li>
                    <NavLink to='/profile' className='profile__link'>
                      <span>
                        <Person2Icon />
                      </span>
                      Читальня
                    </NavLink>
                  </li>
                  {authRole?.role === "Admin" && (
                    <li>
                      <NavLink to='/admin' className='profile__link'>
                        <span>
                          <Person2Icon />
                        </span>
                        Админ
                      </NavLink>
                    </li>
                  )}

                  <li onClick={logout}>
                    <span>
                      <LogoutIcon />
                    </span>
                    Вийти
                  </li>
                </ul>
              </>
            ) : (
              <LoginIcon onClick={() => setActiveModal(true)} />
            )}
          </div>

          {activeMenu && (
            <>
              <button className='google-auth-button'>
                <img
                  src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg'
                  alt='Google logo'
                />
                {currentUser ? (
                  <span onClick={logout}>Вийти з акаунту</span>
                ) : (
                  <span onClick={useLogin}>Увійти через Google</span>
                )}
              </button>

              {currentUser ? (
                <div className='user__profile__mobile'>
                  <span>
                    <PersonIcon />
                  </span>
                  <Link to='profile' className='user__profile__mobile-button'>
                    Читальня
                  </Link>
                </div>
              ) : (
                <p className='privacy'>
                  Продовжуючи відвідування сайту, ви погоджуєтесь з&nbsp;
                  <Link to='/privacy' className='privacy__link'>
                    Політикою конфіденційності
                  </Link>
                </p>
              )}
              <div className='icons'>
                <Icons />
              </div>
            </>
          )}
        </nav>
        <button className='burger' onClick={() => handleActiveMenu()}>
          <FaBars />
        </button>
        {activeMenu && (
          <button className='exit' onClick={() => handleActiveMenu()}>
            <FaTimes />
          </button>
        )}
      </header>
      <Modal
        setActiveModal={setActiveModal}
        activeModal={activeModal}
        useLogin={useLogin}
      />
    </div>
  );
};
