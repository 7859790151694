import React from "react";
import ScrollToTop from "react-scroll-to-top";
import pencil from "../../img/Scroll/pencil.png";

import "./Scroll.scss";

export const Scroll = () => {
  return (
    <div className='scroll'>
      <ScrollToTop
        smooth
        component={
          <div className='pencil'>
            <img src={pencil} alt='' />
          </div>
        }
      />
    </div>
  );
};
