import React from 'react';

import './Page404.scss';
export const Page404 = () => {
  return (
    <div className="notFound">
      <img src={require('./404.png')} alt="" />
    </div>
  );
};
