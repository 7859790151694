import { Link } from "react-router-dom";
import "./Modal.scss";

const Modal = ({ setActiveModal, activeModal, useLogin }) => {
  return (
    <div
      className={activeModal ? "modal__login active" : "modal__login"}
      onClick={() => setActiveModal(false)}
    >
      <div
        className={activeModal ? "modal__content active" : "modal__content"}
        onClick={(e) => e.stopPropagation()}
      >
        <h3>Авторизація</h3>
        <button className='google-auth-button'>
          <img
            src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg'
            alt='Google logo'
          />
          <span onClick={useLogin}>Увійти через Google</span>
        </button>
        <span className='privacy'>
          Продовжуючи відвідування сайту, ви погоджуєтесь з&nbsp;
          <Link to='/privacy' className='privacy__link'>
            Політикою конфіденційності
          </Link>
        </span>
        <button onClick={() => setActiveModal(false)} className='modal__exit'>
          Вихід
        </button>
      </div>
    </div>
  );
};
export default Modal;
