import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentContent: null,
};

const contentSlice = createSlice({
  name: "content",
  initialState,

  reducers: {
    getInfo: (state, action) => {
      state.currentContent = action.payload;
    },
  },
});

export const { getInfo } = contentSlice.actions;
export default contentSlice.reducer;
