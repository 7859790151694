import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,

  reducers: {
    getLogin: (state, action) => {
      state.currentUser = action.payload;
    },
    getLogout: (state) => {
      state.currentUser = null;
    },
  },
});

export const { getLogin, getLogout } = authSlice.actions;
export default authSlice.reducer;
