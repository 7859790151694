import React from 'react';
import { Chart } from 'react-google-charts';
import { WithoutGenre } from '../WithoutGenre/WithoutGenre';

import './Pie.scss';
export const options = {
  title: 'My Daily Activities',
  pieSliceText: 'percentage',
  colors: [
    '#b8b5e8',
    '#ff9690',
    '#ffda8b',
    '#91c5ec',
    '#67a27e',
    '#d2e1ee',
    '#f6725a',
    '#c7e6d0',
    '#ceb582',
  ],
  chartArea: {
    left: '3%',
    top: '3%',
    height: '100%',
    width: '100%',
  },
};

export function Pie({ data, noGenres }) {
  if (data.length) {
    let res = [['Task', 'Hours per Day']].concat(data);
    return (
      <div className="Pie">
        <Chart chartType="PieChart" data={res} options={options} />
        {noGenres > 0 && <p>{`Книжок без жанру: ${noGenres}`}</p>}
      </div>
    );
  } else {
    return (
      <div className="Pie">
        <WithoutGenre />
        {noGenres > 0 && <p>{`Книжок без жанру: ${noGenres}`}</p>}
      </div>
    );
  }
}
