import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import contentSlice from "./slices/contentSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    content: contentSlice,
  },
});

export default store;
