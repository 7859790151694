import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import './ProgressBar.scss';

export const ProgressBar = ({ counter, count, medalName }) => {
  const getColor = (num) => {
    const colors = ['#d6d6d6', '#ffda8b', '#91c5ec', '#b8b5e8', '#67a27e'];

    if (num === 0) {
      return colors[0];
    }

    if (num > 0 && num <= 25) {
      return colors[1];
    }

    if (num >= 25 && num <= 50) {
      return colors[2];
    }

    if (num >= 50 && num <= 75) {
      return colors[3];
    }

    if (num >= 75 && num <= 100) {
      return colors[4];
    }
  };

  return (
    <div className="progressBar">
      <img
        src={require(`../../img/User/Medals/med${count}.png`)}
        alt=""
        className="img"
        style={{ opacity: `${counter ? 1 : 0.5}` }}
      />
      <p className="medalName">{medalName}</p>
      <CircularProgressbar
        value={counter}
        text={`${counter}%`}
        strokeWidth={12}
        styles={buildStyles({
          pathColor: getColor(counter),
          textColor: getColor(counter),
          trailColor: '#d6d6d6',
          backgroundColor: '#3e98c7',
          textSize: '22px',
        })}
        className="circle"
      />
    </div>
  );
};
