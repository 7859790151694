import React, { useState, useEffect } from "react";
import "./Data.scss";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../utils/fire";

export const Data = () => {
  const [formData, setFormData] = useState({
    top5: {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
    },
    statMonth: {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
    },
    statAll: {
      input1: "",
      input2: "",
      input3: "",
    },
  });

  const [oldData, setOldData] = useState({
    top5: {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
    },
    statMonth: {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
    },
    statAll: {
      input1: "",
      input2: "",
      input3: "",
    },
  });
  const [toggle, setToggle] = useState(true);

  const handleForm1Change = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      top5: {
        ...formData.top5,
        [name]: value,
      },
    });
  };

  const handleForm2Change = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      statMonth: {
        ...formData.statMonth,
        [name]: value,
      },
    });
  };

  const handleForm3Change = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      statAll: {
        ...formData.statAll,
        [name]: value,
      },
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    await updateDoc(doc(db, "month-stat", "statistics"), formData);
    setToggle(false);
    setFormData({
      top5: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
      },
      statMonth: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
      },
      statAll: {
        input1: "",
        input2: "",
        input3: "",
      },
    });
    getOldStatistics();
  };

  const getOldStatistics = async () => {
    const userRef = doc(db, "month-stat", "statistics");

    try {
      const statistics = await getDoc(userRef);
      let doc = statistics.data();
      setOldData(doc);
      setToggle(false);
    } catch (error) {
      toast.error("Помилка при авторизації, спробуйте пізніше");
    }
  };

  useEffect(() => {
    getOldStatistics();
  }, [toggle]);

  return (
    <div className='Data'>
      <form onSubmit={handleSubmit}>
        <div className='all_forms'>
          <div className='form'>
            <p className='form__title'>Топ 5 читунів за прошлый месяц</p>
            <div className='item'>
              <label htmlFor='top5-input1'>
                Внесено: {oldData?.top5.input1}
              </label>
              <input
                type='text'
                id='top5-input1'
                name='input1'
                value={formData.top5.input1}
                onChange={handleForm1Change}
              />
            </div>
            <div className='item'>
              <label htmlFor='top5-input2'>
                Внесено: {oldData?.top5.input2}
              </label>
              <input
                type='text'
                id='top5-input2'
                name='input2'
                value={formData.top5.input2}
                onChange={handleForm1Change}
              />
            </div>
            <div className='item'>
              <label htmlFor='top5-input3'>
                Внесено: {oldData?.top5.input3}
              </label>
              <input
                type='text'
                id='top5-input3'
                name='input3'
                value={formData.top5.input3}
                onChange={handleForm1Change}
              />
            </div>
            <div className='item'>
              <label htmlFor='top5-input4'>
                Внесено: {oldData?.top5.input4}
              </label>
              <input
                type='text'
                id='top5-input4'
                name='input4'
                value={formData.top5.input4}
                onChange={handleForm1Change}
              />
            </div>
            <div className='item'>
              <label htmlFor='top5-input5'>
                Внесено: {oldData?.top5.input5}
              </label>
              <input
                type='text'
                id='top5-input5'
                name='input5'
                value={formData.top5.input5}
                onChange={handleForm1Change}
              />
            </div>
          </div>
          <div className='form'>
            <p className='form__title'>Статистика за прошлый месяц</p>
            <div className='item'>
              <label htmlFor='statMonth-input1'>
                Внесено: {oldData?.statMonth.input1}
              </label>
              <input
                type='text'
                id='statMonth-input1'
                name='input1'
                value={formData.statMonth.input1}
                onChange={handleForm2Change}
              />
            </div>
            <div className='item'>
              <label htmlFor='statMonth-input2'>
                Внесено: {oldData?.statMonth.input2}
              </label>
              <input
                type='text'
                id='statMonth-input2'
                name='input2'
                value={formData.statMonth.input2}
                onChange={handleForm2Change}
              />
            </div>
            <div className='item'>
              <label htmlFor='statMonth-input3'>
                Внесено: {oldData?.statMonth.input3}
              </label>
              <input
                type='text'
                id='statMonth-input3'
                name='input3'
                value={formData.statMonth.input3}
                onChange={handleForm2Change}
              />
            </div>
            <div className='item'>
              <label htmlFor='statMonth-input4'>
                Внесено: {oldData?.statMonth.input4}
              </label>
              <input
                type='text'
                id='statMonth-input4'
                name='input4'
                value={formData.statMonth.input4}
                onChange={handleForm2Change}
              />
            </div>
            <div className='item'>
              <label htmlFor='statMonth-input5'>
                Внесено: {oldData?.statMonth.input5}
              </label>
              <input
                type='text'
                id='statMonth-input5'
                name='input5'
                value={formData.statMonth.input5}
                onChange={handleForm2Change}
              />
            </div>
          </div>
          <div className='form'>
            <p className='form__title'>Статистика общая</p>
            <div className='item'>
              <label htmlFor='statAll-input1'>
                Внесено: {oldData?.statAll.input1}
              </label>
              <input
                type='text'
                id='statAll-input1'
                name='input1'
                value={formData.statAll.input1}
                onChange={handleForm3Change}
              />
            </div>
            <div className='item'>
              <label htmlFor='statAll-input2'>
                Внесено: {oldData?.statAll.input2}
              </label>
              <input
                type='text'
                id='statAll-input2'
                name='input2'
                value={formData.statAll.input2}
                onChange={handleForm3Change}
              />
            </div>
            <div className='item'>
              <label htmlFor='statAll-input3'>
                Внесено: {oldData?.statAll.input3}
              </label>
              <input
                type='text'
                id='statAll-input3'
                name='input3'
                value={formData.statAll.input3}
                onChange={handleForm3Change}
              />
            </div>
          </div>
        </div>
        <button type='submit' className='button'>
          Отправить
        </button>
      </form>
    </div>
  );
};
