import React from "react";
import { Data } from "../../../components/Data/Data";
import { Upload } from "../../../components/Upload/Upload";
import Sidebar from "../../../components/Sidebar/Sidebar";

import "./UploadPanel.scss";
import { HeaderAdmin } from "../../../components/HeaderAdmin/HeaderAdmin";

export const UploadPanel = () => {
  return (
    <section className='uploadPanel'>
      <HeaderAdmin />
      <div className='main'>
        <div className='slider'>
          <Sidebar />
        </div>
        <div className='content'>
          <Data />
          <Upload />
        </div>
      </div>
    </section>
  );
};
