import React from "react";
import CountUp from "react-countup";

import "./Statistics.scss";
import str from "../../img/Statistics/str.png";
import book from "../../img/Statistics/book.png";
import vidguk from "../../img/Statistics/vidguk.png";

const Statistics = ({ data }) => {
  return (
    <div className='statistics '>
      <div className='container stat__list'>
        <div className='stat__item'>
          <div className='stat__item-logo'>
            <img src={str} alt='' />
          </div>
          <div className='stat__item-text'>
            <div className='stat__item-number'>
              <span>
                {
                  <CountUp
                    start={0}
                    end={String(data.input1).split(" ")[0]}
                    duration={1}
                    delay={0}
                  />
                }
              </span>
              <span> млн</span>
            </div>
            <p className='stat__item-desc'>СТОРІНОК</p>
          </div>
        </div>
        <div className='stat__item stat__item-border'>
          <div className='stat__item-logo'>
            <img src={book} alt='' />
          </div>
          <div className='stat__item-text'>
            <div className='stat__item-number'>
              <span>
                <CountUp
                  start={0}
                  end={String(data.input2).split(" ")[0]}
                  delay={1}
                  duration={1}
                />
              </span>
              <span> тис</span>
            </div>
            <p className='stat__item-desc'>КНИЖОК</p>
          </div>
        </div>
        <div className='stat__item'>
          <div className='stat__item-logo'>
            <img src={vidguk} alt='' />
          </div>
          <div className='stat__item-text'>
            <span className='stat__item-number'>
              <CountUp start={0} end={data.input3} delay={2} duration={1} />
            </span>
            <p className='stat__item-desc'>ВІДГУКІВ</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
