import useAuth from "../hooks/useAuth";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { signOut } from "firebase/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { auth } from "../utils/firebase.auth";
import { useDispatch, useSelector } from "react-redux";
import { getLogout } from "../redux/slices/authSlice";

const ProtectedRoute = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authRole = useSelector((state) => state.auth.currentUser);

  const logout = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  if (!currentUser) {
    logout();
    dispatch(getLogout());
  }

  if (!authRole || authRole.role !== "Admin") {
    navigate("/");
  }

  return currentUser && authRole?.role === "Admin" ? (
    <Outlet />
  ) : (
    <Navigate to='/' />
  );
};
export default ProtectedRoute;
