import { Link } from "react-router-dom";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SearchIcon from "@mui/icons-material/Search";
import ArticleIcon from "@mui/icons-material/Article";

import "./sidebar.scss";

const Sidebar = ({ handleLogOut }) => {
  return (
    <div className='sidebar'>
      <div className='center'>
        <ul>
          <p className='title'>ADMIN</p>
          <Link to='/admin' style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className='icon' />
              <span>Dashboard</span>
            </li>
          </Link>
          <Link to='/admin/users' style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className='icon' />
              <span>Users</span>
            </li>
          </Link>
          <Link to='/admin/upload' style={{ textDecoration: "none" }}>
            <li>
              <InsertChartIcon className='icon' />
              <span>Upload</span>
            </li>
          </Link>
          <Link to='/admin/upload2' style={{ textDecoration: "none" }}>
            <li>
              <InsertChartIcon className='icon' />
              <span>Upload-2</span>
            </li>
          </Link>
          <Link to='/admin/info-about-books' style={{ textDecoration: "none" }}>
            <li>
              <InsertChartIcon className='icon' />
              <span>Info About Books</span>
            </li>
          </Link>
          <Link to='/admin/search-books' style={{ textDecoration: "none" }}>
            <li>
              <SearchIcon className='icon' />
              <span>Search</span>
            </li>
          </Link>
          <p className='title'>Articles</p>
          <Link to='/admin/articles' style={{ textDecoration: "none" }}>
            <li>
              <ArticleIcon className='icon' />
              <span>Articles</span>
            </li>
          </Link>
          <Link to='/admin/add-article' style={{ textDecoration: "none" }}>
            <li>
              <ArticleIcon className='icon' />
              <span>Add Article</span>
            </li>
          </Link>
          <p className='title'>SITE</p>
          <li>
            <ExitToAppIcon className='icon' />
            <span onClick={handleLogOut}>Logout</span>
          </li>
        </ul>
      </div>
      <div className='bottom'>
        <div className='colorOptions'></div>
        <div className='colorOptions'></div>
      </div>
    </div>
  );
};

export default Sidebar;
