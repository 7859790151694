import React, { useState } from 'react';
import { Accordin } from '../../components/FaqBlock/Accordin';
import { Button } from '../../components/Button/Button';
import './faq.css';

export const FaqAll = () => {
  const [active, setActive] = useState('');

  return (
    <div className="faq">
      <div className="accordion">
        <Accordin
          title={'Як зареєструватися в марафоні?'}
          content={
            <p>
              Реєстрація в марафон проходить через
              <a href="http://t.me/Monthreadbot" target="_blank" rel="noreferrer">
                Читайбота
              </a>
              . Крім того, щоб ваші дані були зараховані вам необхідно зробити репост з постом
              анонсом марафону в інстаграм до себе в профіль(на час воєнного стану ця умова не діє),
              а також, бути підписаним на організатора. В разі, якщо ви дійшли до кінця марафону,
              але репост не робили, то, на жаль, результат зараховано не буде.
            </p>
          }
          active={active}
          setActive={setActive}
        />
        <Accordin
          title={'Треба вести трекер чи писати в бота?'}
          content={
            "Обов'язково треба вести записи в читайботі. Трекер читання ви можете вести за власним бажанням."
          }
          active={active}
          setActive={setActive}
        />
        <Accordin
          title={'Як рахувати сторінки?'}
          content={
            'Сторінки рахуються за день. Ви читаєте книгу і лише в кінці дня вносите ОДНЕ число, що = сумі прочитаних вами сторінок'
          }
          active={active}
          setActive={setActive}
        />
        <Accordin
          title={'Чому я пишу в бота кожного разу, коли читаю, а він зараховує це як різні дні?'}
          content={
            'Бот не сумує ваші дані, які були введені протягом дня. Всі значення, які введені в бота будуть зараховані різними днями.'
          }
          active={active}
          setActive={setActive}
        />
        <Accordin
          title={'Як видалити неправильні дані?'}
          content={
            'Натисніть в боті кнопку "Помилка" і опишіть ДЕТАЛЬНО, за яку дату потрібно виправити дані і на що саме їх треба виправити. Наприклад: за 1.04 зараховано 120 сторінок, потрібно виправити на 210.'
          }
          active={active}
          setActive={setActive}
        />
        <Accordin
          title={'Чому я прошу виправляти помилки, а вони не виправляються в ту саму мить?'}
          content={
            'Всі скарги/пропозиції/помилки обробляються вручну і можуть бути виправлені відразу або ж протягом дня чи навіть тижня. Не потрібно дублювати одну і ту саму помилку 10 разів, швидше її не виправлять. Більш за те, через такий спам можуть пропустити чиєсь повідомлення.'
          }
          active={active}
          setActive={setActive}
        />
        <Accordin
          title={'Я забув внести дані в читайбота, що робити?'}
          content={
            'Дані можна вносити з запізненням. Тому, якщо ви пропустили день, введіть значення в той момент, коли згадали про це.'
          }
          active={active}
          setActive={setActive}
        />

        <Accordin
          title={'Що ще вміє бот?'}
          content={
            'В бота можна писати список своїх прочитаних книг, вносити посилання на відгуки в інстаграмі, а також бачити свою статистику за весь період участі в марафоні та отримувати нагороди.'
          }
          active={active}
          setActive={setActive}
        />
        <Accordin
          title={"Обов'язково записувати прочитані книги?"}
          content={
            'Ні. Але, якщо ви читаєте понад 500 сторінок в день, то в учасників може виникати питання, щодо чесності ваших даних. Щоб уникнути зайвих підозр краще вносити, книги до списку, тим самим підтверджуючи чесність ваших результатів.'
          }
          active={active}
          setActive={setActive}
        />
        <Accordin
          title={'Я брав участь в марафоні, але не дійшов до кінця. Моя статистика зникне?'}
          content={
            'Так, читайбот зберігає дані тільки тих учасників, які дійшли до кінця всіх марафонів. Якщо в якийсь місяць ви вибули, то ваші дані видаляються. Тому, рекомендуємо не сходити з дистанції 🤗'
          }
          active={active}
          setActive={setActive}
        />
        <Accordin
          title={'Я щось натиснув і кнопки зникли'}
          content={'Напишіть в бота /start система має оновитися.'}
          active={active}
          setActive={setActive}
        />
        <Accordin
          title={'Які книги я можу читати?'}
          content={'Будь-які, обмеження на книги немає'}
          active={active}
          setActive={setActive}
        />

        <Accordin
          title={'Як рахувати електронні книги та аудіокниги?'}
          content={
            'Сторінки електронних та аудіокниг рахуються по факту прочитання. Тобто спочатку прочитайте книгу, потім внесіть загальну суму сторінок, яка зазначена в друкованій версії книги. Решту днів можна ставити 0.'
          }
          active={active}
          setActive={setActive}
        />

        <Accordin
          title={'Я вношу дані, тисну "інфо" для перевірки, але моїх даних там не має.'}
          content={
            "На будь-яку вашу дію читайбот дає вам відповідь. Якщо ви ввели дані і бот відповів вам, що дані зараховано - так і є. Система працює так, що ваші дані з'являться у вашій поточній статистиці протягом 1 хвилини. Почекайте і перевірте вашу статистику трішки пізніше."
          }
          active={active}
          setActive={setActive}
        />

        <div className="faq__btn">
          <Button text="ПОВЕРНУТИСЯ НА ГОЛОВНУ СТОРІНКУ" href="/" />
        </div>
      </div>
    </div>
  );
};
