import React from "react";
import { BookSlider } from "../BookSlider/BookSlider";

import "./Top.scss";
const Top = ({ data, aboutBooks }) => {
  return (
    <div className='top10' id='top'>
      <div className='top10__users'>
        <h2>ТОП ЧИТУНІВ</h2>
        <div className='top10__users-list'>
          <div className='user__item'>
            <div className='user__item-avatar'>
              <img src={require("../../img/top1.png")} alt='' />
            </div>
            <div className='user__item-insta'>{data.input1}</div>
          </div>
          <div className='user__item'>
            <div className='user__item-avatar'>
              <img src={require("../../img/top2.png")} alt='' />
            </div>
            <div className='user__item-insta'>{data.input2}</div>
          </div>
          <div className='user__item'>
            <div className='user__item-avatar'>
              <img src={require("../../img/top3.png")} alt='' />
            </div>
            <div className='user__item-insta'>{data.input3}</div>
          </div>
          <div className='user__item'>
            <div className='user__item-avatar'>
              <img src={require("../../img/b3.png")} alt='' />
            </div>
            <div className='user__item-insta'>{data.input4}</div>
          </div>
          <div className='user__item'>
            <div className='user__item-avatar'>
              <img src={require("../../img/b3.png")} alt='' />
            </div>
            <div className='user__item-insta'>{data.input5}</div>
          </div>
        </div>
      </div>
      <div className='top10__books'>
        <div className='top__books-title'>
          <p>топ книг минулого місяця</p>
        </div>

        <BookSlider aboutBooks={aboutBooks} className='slider' />
      </div>
    </div>
  );
};

export default Top;
