import "./Preview.scss";

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const Preview = ({ onDelete, image }) => {
  const { url, name, size } = image;

  return (
    <div className="preview">
      {image.ref && <span className="badge">Загружено</span>}
      <div className="delete" onClick={() => onDelete(image)}>
        x
      </div>

      <div className="wrapperImage">
        <img className="image" alt="2" src={url} />
      </div>

      <div className="title">{name}</div>
      <div style={{ textAlign: "end", fontSize: 14 }}>{formatBytes(size)}</div>
    </div>
  );
};
