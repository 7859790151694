import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./BookSlider.scss";
export const BookSlider = ({ aboutBooks }) => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: (
      <img
        className="slick-prev"
        src={require("../../img/arrow1.png")}
        alt=""
      />
    ),
    nextArrow: (
      <img
        className="slick-next"
        src={require("../../img/arrow2.png")}
        alt=""
      />
    ),
    responsive: [
      {
        breakpoint: 1101,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider">
      <Slider {...settings} className="items">
        {aboutBooks.map((item, index) => {
          return (
            <div className="itemTest" key={index * Math.random()}>
              <img
                className="front"
                src={`https://firebasestorage.googleapis.com/v0/b/admin-panel-web-marafon.appspot.com/o/bookImages%2Fk${item.number}.jpg?alt=media`}
                alt=""
              />

              <div className="back">
                <div>
                  <p className="number">#{item.number}</p>
                  <p className="author">{item.info}</p>
                  <p className="genre">{item.genre}</p>
                  <p className="month">{item.repeat1}</p>
                  <p className="month">{item.repeat2}</p>
                  <p className="month">{item.repeat3}</p>
                </div>
                <div className="btn">
                  <button>
                    <a
                      href={item.aboutBook}
                      alt=""
                      target="_blank"
                      rel="noreferrer"
                    >
                      про книгу
                    </a>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
