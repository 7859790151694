import './ChartPages.scss';
import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const Chart = ({ title, data, xAxis, yAxis }) => {
  return (
    <div className="chartPage">
      <div className="title">{title}</div>
      <ResponsiveContainer width="100%" aspect={3 / 1} height="100%" className="chartPages">
        <AreaChart
          width={900}
          height={500}
          data={data}
          margin={{
            top: 10,
            right: 35,
            left: 5,
            bottom: 0,
          }}
          className="area">
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey={xAxis} stroke="gray" fontSize={11} />
          <YAxis fontSize={13} />
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip />
          <Area type="monotone" dataKey={yAxis} stroke="#8884d8" fill="#8884d8" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
