import React, { useState, useEffect, useCallback } from "react";
import { Routes, Route } from "react-router-dom";
import { Article } from "../pages/Article/Article";
import { ArticlesPage } from "../pages/ArticlesPage/ArticlesPage";
import { FaQPage } from "../pages/Faq/FaQPage";
import Home from "../pages/Home/Home";
import { UserMonthStatistics } from "../pages/UserMonthStatistics/UserMonthStatistics";
import { collection, getDocs } from "firebase/firestore";
import { db as db_auth } from "../utils/firebase.auth";
import { db } from "../utils/fire";
import ProtectedRoute from "./ProtectedRoute";
import { DashBoard } from "../pages/Admin/DashBoard/DashBoard";
import { UploadPanel } from "../pages/Admin/UploadPanel/UploadPanel";
import { BookSearch } from "../pages/Admin/BookSearch/BookSearch";
import { ArticlesList } from "../pages/Admin/Articles/ArticlesList/ArticlesList";
import { EditArticle } from "../pages/Admin/Articles/EditArticle/EditArticle";
import { AddArticle } from "../pages/Admin/Articles/AddArticle/AddArticle";
import { Page404 } from "../pages/NotFound/Page404";
import { Profile } from "../pages/Profile/Profile";
import ProtectedUserRoute from "./ProtectedUserRoute";
import RegUsersList from "../pages/Admin/RegUsersList/RegUsersList";
import Privacy from "../pages/Privacy/Privacy";
import { InfoAboutBooks } from "../pages/Admin/InfoAboutBooks/InfoAboutBooks";
import { UploadPanel2 } from "../pages/Admin/UploadPanel2/UploadPanel2";
import ReadAlready from "../pages/ReadAlready/ReadAlready";
import { User } from '../pages/User-Year-UnActive/User';

const Routers = () => {
   const [articles, setArticles] = useState([]);
   const [toggleArticle, setToggleArticle] = useState(true);
   const [users, setUsers] = useState([]);

   const getArticles = useCallback(async () => {
      const articleRef = collection(db, "Articles");
      try {
         const data = await getDocs(articleRef);
         setArticles(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      } catch (error) {
         console.log("error", error);
      }
   }, [articles]);

   const getUsers = useCallback(async () => {
      const articleRef = collection(db_auth, "users");
      try {
         const data = await getDocs(articleRef);
         setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      } catch (error) {
         console.log("error", error);
      }
   }, [users]);

   useEffect(() => {
      getArticles();
   }, [toggleArticle]);

   return (
      <Routes>
         <Route path='*' element={<Page404 to='/' />} />
         <Route path='/' element={<Home articles={articles} />} />
         <Route path='faq' element={<FaQPage />} />
         <Route path='read-already' element={<ReadAlready />} />
         <Route path='articles' element={<ArticlesPage articles={articles} />} />
         <Route path='articles/:id' element={<Article articles={articles} />} />
         <Route path='stat/:id' element={<UserMonthStatistics />} />
         <Route path='year/:id' element={<User />} />
         <Route path='privacy' element={<Privacy />} />

         <Route path='/*' element={<ProtectedRoute />}>
            <Route path='admin' element={<DashBoard />} />
            <Route
               path='admin/users'
               element={<RegUsersList users={users} getUsers={getUsers} />}
            />
            <Route path='admin/upload' element={<UploadPanel />} />
            <Route path='admin/upload2' element={<UploadPanel2 />} />
            <Route path='admin/info-about-books' element={<InfoAboutBooks />} />
            <Route path='admin/search-books' element={<BookSearch />} />
            <Route
               path='admin/articles'
               element={
                  <ArticlesList
                     articles={articles}
                     setToggleArticle={setToggleArticle}
                     toggleArticle={toggleArticle}
                  />
               }
            />
            <Route
               path='admin/articles/edit/:id'
               element={
                  <EditArticle
                     setToggleArticle={setToggleArticle}
                     toggleArticle={toggleArticle}
                     articles={articles}
                  />
               }
            />

            <Route
               path='admin/add-article'
               element={
                  <AddArticle
                     toggleArticle={toggleArticle}
                     articles={articles}
                     setToggleArticle={setToggleArticle}
                  />
               }
            />
         </Route>

         <Route path='/*' element={<ProtectedUserRoute />}>
            <Route path='profile' element={<Profile />} />
         </Route>
      </Routes>
   );
};
export default Routers;
