import React from 'react';
import { NavLink } from 'react-router-dom';

import './HeaderAdmin.scss';

export const HeaderAdmin = ({ handleLogOut }) => {
  return (
    <nav className="headerAdmin">
      <NavLink to="/">
        <button className="btn">Вернуться на сайт</button>
      </NavLink>
      <button className="btn" onClick={handleLogOut}>
        Log Out
      </button>
    </nav>
  );
};
