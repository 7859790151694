import React, { useState, useCallback, useEffect } from "react";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../../../utils/fire";
import ReactMarkdown from "react-markdown";
import SimpleMDE from "react-simplemde-editor";
import { useParams } from "react-router";
import { useNavigate } from "react-router";

import "./EditArticle.scss";
import "easymde/dist/easymde.min.css";
import { HeaderAdmin } from "../../../../components/HeaderAdmin/HeaderAdmin";
import Sidebar from "../../../../components/Sidebar/Sidebar";

export const EditArticle = ({ toggleArticle, setToggleArticle }) => {
  const { id } = useParams();

  const [editArticle, setEditArticle] = useState(null);

  const [title, setTitle] = useState(null);
  const [image, setImage] = useState(null);
  const [text, setText] = useState(null);
  const [chapter, setChapter] = useState(null);
  const [views, setViews] = useState(null);
  const [status, setStatus] = useState(null);

  const getArticle = useCallback(async () => {
    const articleRef = doc(db, "Articles", id);

    try {
      const doc = await getDoc(articleRef);
      setEditArticle(doc.data());
    } catch (error) {
      console.log("error", error);
    }
  }, [editArticle]);

  useEffect(() => {
    getArticle();
  }, []);

  useEffect(() => {
    if (editArticle) {
      setTitle(editArticle.title);
      setImage(editArticle.image);
      setText(editArticle.text);
      setChapter(editArticle.chapter);
      setViews(editArticle.views);
      setStatus(editArticle.status);
    }
  }, [editArticle]);

  const onChangeText = useCallback((value) => {
    setText(value);
  }, []);

  const navigate = useNavigate();

  const UpdatePost = async () => {
    const docRef = doc(db, "Articles", id);
    await updateDoc(docRef, { text, image, title, views, chapter, status });
    await setToggleArticle(!toggleArticle);

    navigate(`/admin/articles`);
  };

  const goBack = () => {
    navigate(`/admin/articles`);
  };

  const changeChapter = (chapter) => {
    setChapter(chapter);
  };

  const changeStatus = (status) => {
    setStatus(status);
  };

  return (
    <section className='EditArticle'>
      <HeaderAdmin />
      <div className='main'>
        <div className='sidebar'>
          <Sidebar />
        </div>
        <div className='content'>
          <h2>Edit article</h2>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              placeholder='Title'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              type='text'
              className='form-control'
              placeholder='Image'
              value={image}
              onChange={(e) => setImage(e.target.value)}
            />
            <form>
              <select
                onChange={(event) => changeChapter(event.target.value)}
                className='select'
                value={chapter}
              >
                <option value='Новини'>Новини</option>
                <option value='Підбірки'>Підбірки</option>
                <option value="Інтерв'ю">Інтерв'ю</option>
                <option value='Навколокнижкове'>Навколокнижкове</option>
              </select>
              <select
                onChange={(event) => changeStatus(event.target.value)}
                className='select'
                value={status}
              >
                <option value='Публиковать'>Публиковать</option>
                <option value='На модерации'>На модерации</option>
              </select>
            </form>
            <input
              type='text'
              className='form-control'
              placeholder='Просмотры'
              value={views}
              onChange={(e) => setViews(e.target.value)}
            />
          </div>
          <SimpleMDE value={text} onChange={onChangeText} />
          <div className='buttons'>
            <button onClick={UpdatePost}>Обновить</button>
            <button onClick={goBack}>Вернуться Назад</button>
          </div>

          <div className='text'>
            <p>{title}</p>
            <p>{chapter}</p>
            <p>Статус: {status}</p>
            <img src={image} alt='' />
            <ReactMarkdown>{text}</ReactMarkdown>
          </div>
        </div>
      </div>
    </section>
  );
};
