import React from "react";
import { Data2 } from "../../../components/Data/Data2";
import { Upload2 } from "../../../components/Upload/Upload-2";
import Sidebar from "../../../components/Sidebar/Sidebar";

import "./UploadPanel2.scss";
import { HeaderAdmin } from "../../../components/HeaderAdmin/HeaderAdmin";

export const UploadPanel2 = () => {
  return (
    <section className='uploadPanel'>
      <HeaderAdmin />
      <div className='main'>
        <div className='slider'>
          <Sidebar />
        </div>
        <div className='content'>
          <Upload2 />
          <Data2 />
        </div>
      </div>
    </section>
  );
};
