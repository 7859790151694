import Sidebar from "../../../components/Sidebar/Sidebar";
import { HeaderAdmin } from "../../../components/HeaderAdmin/HeaderAdmin";

import "./RegUsersList.scss";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const RegUsersList = ({ users, getUsers }) => {
  return (
    <section className='RegUsersList'>
      <HeaderAdmin />
      <div className='main'>
        <div className='slider'>
          <Sidebar />
        </div>
        <div className='content'>
          <button className='button' onClick={getUsers}>
            Список зарегистрированных учасников
          </button>
          <TableContainer component={Paper} className='reg__users__table'>
            <Table className='table'>
              <TableHead className='head'>
                <TableRow>
                  <TableCell align='left' className='cell'>
                    Name
                  </TableCell>
                  <TableCell align='left' className='cell'>
                    Email
                  </TableCell>
                  <TableCell align='left' className='cell'>
                    RegTime
                  </TableCell>
                  <TableCell align='left' className='cell'>
                    LastTime
                  </TableCell>
                  <TableCell align='left' className='cell'>
                    Role
                  </TableCell>
                </TableRow>
              </TableHead>
              {users && (
                <TableBody className='body'>
                  {users.map((user) => (
                    <TableRow key={user.uid}>
                      <TableCell align='left' className='cell'>
                        {user.displayName}
                      </TableCell>
                      <TableCell align='left' className='cell'>
                        {user.email}
                      </TableCell>
                      <TableCell align='left' className='cell'>
                        {user.creationTime}
                      </TableCell>
                      <TableCell align='left' className='cell'>
                        {user.lastSignInTime}
                      </TableCell>
                      <TableCell align='left' className='cell'>
                        {user.role}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </div>
    </section>
  );
};
export default RegUsersList;
